function getProductData(product, additionalData) {
    return {
        product_id: product.id,
        name: product.title,
        price: product.price.amount / 100,
        currency: product.price.currency,
        ...additionalData
    }
}

function sendEcommerceEvent (event, data) {
    if (typeof zaraz !== 'undefined') {
        zaraz.ecommerce(event, data);
    }
}

export function ecommerce (event, products, additionalData = {}) {
    let data = {}

    if (Array.isArray(products)) {
        data.products = products.map(product => getProductData(product, additionalData))
    } else {
        data = getProductData(products, additionalData)
    }

    sendEcommerceEvent(event, data)
}

export function cartEcommerceEvent (eventName, amount, orderItemsByCompany, additionalFields) {
    const products = []

    orderItemsByCompany.forEach((company) => {
        company.products.forEach((orderItem) => {
            products.push(getProductData(orderItem.product, {
                quantity: orderItem.quantity
            }))
        })
    })

    sendEcommerceEvent(eventName, {
        currency: amount.currency,
        value: amount.amount / 100,
        products,
        ...additionalFields
    })
}

export function setUserData(user) {
    set('user_id', user.id, {scope: 'session'})
    set('email', user.email, {scope: 'session'})
    set('phone', user.phone.substring(1), {scope: 'session'})
}

export function set(key, value, options = {}) {
    if (typeof zaraz !== 'undefined') {
        zaraz.set(key, value, options)
    }
}

export function track(event, options = {}) {
    if (typeof zaraz !== 'undefined') {
        zaraz.track(event, options)
    }
}
